@font-face {
  font-family: 'Fixedsys';
  src: local('Fixedsys'), url(./fonts/Fixedsys.ttf) format('truetype');
}

@font-face {
  font-family: 'FixedsysTTF';
  src: local('FixedsysTTF'), url(./fonts/FixedsysTTF.ttf) format('truetype');
}

@font-face {
  font-family: 'Inconsolata-Bold';
  src: local('Inconsolata-Bold'), url(./fonts/Inconsolata-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inconsolata-Regular';
  src: local('Inconsolata-Regular'), url(./fonts/Inconsolata-Regular.ttf) format('truetype');
}

